<template>
  <v-app>
    <section class="login-section">
      <div class="login-blk">
        <div class="screen-lft">
          <div class="logo">
            <a href="#">
              <img src="@/assets/images/cp-logo-new.png" />
            </a>
          </div>
        </div>
        <div class="screen-rgt">
          <div class="screen-outer">
            <div class="screen-title">
              <h2 class="font-18px">{{ $t("page_login_title") }}</h2>
            </div>
            <div class="screen-form">
              <form>
                <v-text-field
                  :placeholder="$t('page_login_email_placeholder')"
                  filled
                  rounded
                  dense
                  class="input-text"
                  v-model="email"
                ></v-text-field>
                <div class="marin-bottom-field"></div>
                <v-text-field
                  :placeholder="$t('page_login_password_placeholder')"
                  filled
                  rounded
                  dense
                  class="input-text"
                  v-model="password"
                  :type="showPassword ? 'text' : 'password'"
                  hide-details="auto"
                  @keyup="submit"
                ></v-text-field>
                <div class="marin-bottom-field"></div>
                <div class="remember-blk">
                  <div class="custom-cr">
                    <input
                      id="check1"
                      type="checkbox"
                      name="check"
                      value="check1"
                    />
                    <label for="check1" class="font-14px">
                      {{ $t("page_login_remember_text") }}
                    </label>
                  </div>
                </div>
                <v-btn
                  block
                  class="text-capitalize btn-submit"
                  large
                  color="primary"
                  :disabled="!isValid"
                  @click="login"
                >
                  {{ $t("page_login_submit_button") }}
                </v-btn>
                <div class="marin-bottom-field"></div>
                <div class="forget-blk">
                  <span class="forgot-text font-Noto-Sans">
                    <router-link
                      :to="{ name: 'ForgotPassword' }"
                      class="font-14px"
                    >
                      {{ $t("page_login_forgot_password_text") }}
                    </router-link>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="copyright-blk">
      <p class="font-14px">© 2017 - {{ copyrightyear }} redish Co., Ltd.</p>
    </div>
  </v-app>
</template>

<script>
import dayjs from "@/plugins/dayjs";
export default {
  name: "Login",
  data() {
    return {
      showPassword: false,
      email: null,
      password: null,
      isValid: true,
      errors: null,
      copyrightyear: null,
      rules: {
        email: [
          v => !!v || this.$t("page_login_email_required_validation_text")
          /*v =>
            /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
            'E-mail must be valid'*/
        ],
        password: [
          v => !!v || this.$t("page_login_password_required_validation_text"),
          v =>
            (v && v.length >= 6) ||
            this.$t("page_login_password_min_validation_text")
          // v => /(?=.*[A-Z])/.test(v) || 'Must have one uppercase character',
          // v => /(?=.*\d)/.test(v) || 'Must have one number',
          // v => /([!@$%])/.test(v) || 'Must have one special character [!@#$%]'
        ]
      }
    };
  },
  mounted() {
    this.copyrightyear = dayjs().format("YYYY");
  },
  methods: {
    login() {
      let email = this.email;
      let password = this.password;
      this.$store.dispatch("AUTH_REQUEST", { email, password }).then(
        response => {
          if (response.status >= 200 && response.status <= 299) {
            window.location.href = "/shops";
            return;
          } else {
            this.$store.dispatch("ALERT", {
              show: true,
              text: this.$t("page_login_error_invalid")
            });
          }
        },
        error => {
          this.$store.dispatch("ALERT", {
            show: true,
            text: this.$t("page_login_error_invalid")
          });
        }
      );
    },
    submit(key) {
      if (key.key === "Enter") {
        this.login();
      }
    }
  },
  created() {
    this.$store.dispatch("API_PROCESSING", false);
  }
};
</script>
<style lang="scss" src="./_styles.scss"></style>
